import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// Markdown
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

// MUI
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// Node Components
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
const handleDragStart = e => e.preventDefault()

// Local Components
import Layout from '../components/Layout'
import { useStyles } from '../styles/global'

const ProductPageTemplate = ({ data }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { allMarkdownRemark } = data
  const { frontmatter } = allMarkdownRemark?.edges[0].node
  console.log(frontmatter)
  return (
    <div>
      <Layout>
        <Box
          mb={['1rem', '2rem']}
          p={['.5rem', '1rem', '2rem']}
          bgcolor={theme.palette.opaque.default}
        >
          <AliceCarousel
            animationDuration={1000}
            animationType="fadeout"
            autoPlay
            autoPlayInterval={3300}
            autoPlayStrategy="none"
            disableButtonsControls
            infinite
            keyboardNavigation
            mouseTracking
            items={frontmatter?.images?.map(
              ({ image }, index) => (
                <GatsbyImage
                  key={index}
                  image={getImage(image)}
                  alt=""
                  loading="eager"
                  onDragStart={handleDragStart}
                  objectFit="cover"
                />
              ),
            )}
          />
        </Box>
        <Grid container justify="space-around">
          <Grid item xs={12} sm={10} lg={4}>
            <Box
              display="flex"
              flexDirection="row"
              bgcolor={theme.palette.opaque.default}
              p={['1rem']}
              mb={['1rem']}
              ml={['1rem']}
            >
              <Box
                bgcolor="#FFF"
                p={['1rem']}
                width="100%"
                height="100%"
              >
                <a
                  href={frontmatter.brandUrl}
                  target="_blank"
                  title={frontmatter.title}
                >
                  <GatsbyImage
                    image={getImage(frontmatter.brandLogo)}
                    alt={frontmatter.title}
                    style={{ width: '100%' }}
                    loading="eager"
                    objectFit="cover"
                  />
                  </a>
                </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Box
              display="flex"
              flexDirection="column"
              bgcolor={theme.palette.primary.dark}
              width="100%"
              p={['1rem']}
            >
              <Typography variant="h3">
                {frontmatter.title}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              p={['1rem']}
            >
              <Typography
                variant="h5"
                className={classes.light}
              >
                <ReactMarkdown
                  plugins={[gfm]}
                  children={frontmatter.brandSummary}
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="space-around">
          {frontmatter?.descriptions?.map(
            ({ description }, index) => (
              <Grid item xs={11} lg={5}>
                <Box
                  display="flex"
                  flexDirection="column"
                  bgcolor={theme.palette.primary.dark}
                  width="100%"
                  p={['1rem']}
                >
                  <Typography variant="h3">
                    {description.title}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  p={['1rem']}
                  mb={['1rem']}
                >
                  <Typography
                    variant="h5"
                    className={classes.light}
                  >
                    <ReactMarkdown
                      plugins={[gfm]}
                      children={description.detail}
                    />
                  </Typography>
                </Box>
              </Grid>
            ),
          )}
        </Grid>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($slug: String!) {
    allMarkdownRemark(
      filter: { fields: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            brandLogo {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  aspectRatio: 1.77
                  formats: [AUTO, WEBP, AVIF]
                  quality: 80
                )
              }
            }
            brandSummary
            brandUrl
            descriptions {
              description {
                detail
                title
              }
            }
            images {
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    aspectRatio: 1.77
                    formats: [AUTO, WEBP, AVIF]
                    quality: 80
                  )
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
      nodes {
        fields {
          slug
        }
      }
    }
  }
`

export default ProductPageTemplate
